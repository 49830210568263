import React from "react"
import { Link } from "gatsby"
// import React, { useContext } from "react"
// import childrenContext from "../childrenContext"

export function FutureFoundersInfo({
  topHeader,
  topButtonToggle,
  topButton,
  topButtonUrl,
  topDeadline,
  //setModalForm,
}) {
  // const context = useContext(childrenContext)
  const {
    title,
    desc: { text },
  } = topHeader
  return (
    <div className="content co-found">
      <div className="container">
        <div className="title">
          <h2
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          />
          <p>{text}</p>
          {/*
            <button
              style={{ cursor: "pointer", outline: "none" }}
              onClick={context.openContactModal}
              className="btn btn-large btn-red"
            >
              {topButton}
            </button>
          */}
          {topButtonToggle ? (
            topButtonUrl.includes("https://atomic.vc/") ? (
              <Link
                to={topButtonUrl.replace("https://atomic.vc", "")}
                className="btn btn-large btn-red"
              >
                {topButton}
              </Link>
            ) : (
              <a
                href={topButtonUrl}
                className="btn btn-large btn-red"
                target="_blank"
                rel="noreferrer"
              >
                {topButton}
              </a>
            )
          ) : (
            ""
          )}
          {/*
          {topButtonToggle && (
            <button
              style={{ cursor: "pointer", outline: "none" }}
              onClick={() => setModalForm()}
              className="btn btn-large btn-red"
            >
              {topButton}
            </button>
          )}
*/}
          {topDeadline && (
            <p
              className="deadline-notice"
              dangerouslySetInnerHTML={{ __html: topDeadline }}
            />
          )}
        </div>
      </div>
    </div>
  )
}
