import React from "react"

export const NextArrow = ({ className, style, onClick }) => (
  <div className={className} style={{ ...style }} onClick={onClick}>
    <svg
      width="22"
      height="18"
      viewBox="0 0 22 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 9H20M20 9L12.3404 1M20 9L12.3404 17"
        stroke="currentColor"
        strokeWidth="2"
      />
    </svg>
  </div>
)

export const PrevArrow = ({ className, style, onClick }) => (
  <div className={className} style={{ ...style }} onClick={onClick}>
    <svg
      width="22"
      height="18"
      viewBox="0 0 22 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 9H2M2 9L9.65957 1M2 9L9.65957 17"
        stroke="currentColor"
        strokeWidth="2"
      />
    </svg>
  </div>
)
