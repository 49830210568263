import React from "react"
import parse from "html-react-parser"
import { Image } from "../../Image"

export const FindCompany = ({
  findCompanyTitle,
  findTheCompanyDescription,
  findTheCompanyCards,
}) => (
  <div className="co-found-find-company">
    <div className="container">
      {findCompanyTitle && (
        <h2 className="co-found-find-company__title">
          {parse(findCompanyTitle)}
        </h2>
      )}
      {findTheCompanyDescription && (
        <p className="co-found-find-company__description">
          {parse(findTheCompanyDescription)}
        </p>
      )}

      <div className="co-found-find-company__row">
        {findTheCompanyCards?.map((card, index) => (
          <div key={index} className="co-found-find-company__col">
            <div className="card">
              <Image className="card__image" image={card.image} />
              <div className="card__info">
                <h4 className="card__title">{parse(card.title)}</h4>
                <p className="card__description">{parse(card.description)}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
)
