import React from "react"
import { Image } from "../../Image"

export const RecentPostsItem = props => {
  const { title, image, onClickHandler } = props

  return (
    <>
      <div
        className="recent-article"
        onClick={() => onClickHandler(props)}
        role="button"
        tabIndex={0}
      >
        <div>
          <Image className="img" image={image} />
          <h3 className="recent-article__title">{title}</h3>
        </div>
        <div>
          <span className="recent-article__more">Read more</span>
        </div>
      </div>
      {/* eslint-enable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
    </>
  )
}
