import React, { useState } from "react"
import Slider from "react-slick"
import { Image } from "../../Image"
import { NextArrow, PrevArrow } from "./Arrows"
import parse from "html-react-parser"

export const TestimonialsSlider = ({
  slides,
  setActiveCompany,
  setMemberModal,
  slidersCount,
}) => {
  const slideCount = slides.length

  let [activeSlide, setActiveSlide] = useState(0)
  let [textSliderState, setTextSliderState] = useState()
  let [mainImageSliderState, setMainImageSliderState] = useState()

  const settings = {
    dots: false,
    arrows: false,
    fade: true,
    speed: 0,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    swipe: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (current, next) => setActiveSlide(next),
  }

  if (!slides.length) {
    return ""
  }

  function getSlideClass(index) {
    if (index === activeSlide) {
      return "current"
    } else if (
      index === activeSlide + 1 ||
      (activeSlide === slideCount - 1 && index === 0)
    ) {
      return "next"
    } else if (
      index === activeSlide - 1 ||
      (activeSlide === 0 && index === slideCount - 1)
    ) {
      return "prev"
    } else {
      return ""
    }
  }

  return (
    <>
      <div className="testimonials-slider">
        <div className="container">
          <div className="testimonials-slider__wrapper">
            <div className="testimonials-slider--text">
              <Slider
                {...settings}
                asNavFor={mainImageSliderState}
                speed={400}
                adaptiveHeight={true}
                ref={slider => setTextSliderState(slider)}
              >
                {slides?.map((slide, index) => {
                  const {
                    role,
                    text: { text },
                    refCompany,
                    referenceTeamMember,
                  } = slide
                  return (
                    <React.Fragment key={index}>
                      <div
                        className={`testimonials-slider__content ${getSlideClass(
                          index
                        )}`}
                      >
                        <h3 className="testimonial-text">{parse(text)}</h3>
                        <div className="author-info">
                          <p className="author-name">
                            <b
                              onClick={() =>
                                setMemberModal(referenceTeamMember)
                              }
                              role="button"
                              tabIndex={0}
                            >
                              {referenceTeamMember.name}
                            </b>
                          </p>
                          <p className="author-position">
                            <span>{role}</span>
                            <span>
                              <b
                                className="author-company"
                                onClick={() => setActiveCompany(refCompany)}
                                role="button"
                                tabIndex={0}
                              >
                                , {refCompany.name}
                              </b>
                            </span>
                          </p>
                        </div>
                      </div>
                    </React.Fragment>
                  )
                })}
              </Slider>
            </div>
            <div className="testimonials-slider--main">
              <div className="testimonials-slider__mwr">
                {[...Array(slidersCount)].map((x, i) => {
                  const allSlides = [...slides]
                  const slideMove = allSlides.splice(0, i)
                  const slidesView = [...allSlides, ...slideMove]

                  return (
                    <div key={i}>
                      <Slider
                        {...settings}
                        asNavFor={textSliderState}
                        arrows={i === 0}
                        ref={slider => {
                          if (i === 0) {
                            setMainImageSliderState(slider)
                          }
                        }}
                      >
                        {slidesView.map((slide, index) => {
                          const { referenceTeamMember } = slide

                          return (
                            <React.Fragment key={index}>
                              <div
                                onClick={() => {
                                  if (i === 0) {
                                    setMemberModal(referenceTeamMember)
                                  } else {
                                    textSliderState.slickNext()
                                  }
                                }}
                              >
                                <Image
                                  className={`img ${getSlideClass(index)}`}
                                  image={referenceTeamMember.smallPhoto}
                                />
                              </div>
                            </React.Fragment>
                          )
                        })}
                      </Slider>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
