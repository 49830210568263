import React from "react"
import parse from "html-react-parser"
import { Image } from "../../Image"
import Marquee from "react-fast-marquee"

export const Benefits = ({ benefits }) => {
  const marqueeSettings = {
    pauseOnHover: true,
    speed: 60,
    direction: "left",
    //centerMode: true,
    // centerPadding: '30px',
    // slidesToShow: 3,
    // slidesToScroll: 1,
    // variableWidth: true,
    // infinite: true,
    // arrows: false,
    // autoplay: true,
    // speed: 10000,
    // autoplaySpeed: 0,
    // cssEase: "linear",
  }

  return (
    <div className="benefits">
      <div className="container">
        <div className="benefits__wrapper">
          <Marquee {...marqueeSettings} className="benefits__ticker">
            {benefits?.map((benefit, index) => (
              <div key={index} className="benefit">
                <Image className="benefit__icon" image={benefit.icon} />
                <span className="benefit__text">{parse(benefit.title)}</span>
              </div>
            ))}
          </Marquee>
        </div>
      </div>
    </div>
  )
}
