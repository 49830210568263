import React, { useEffect, useState } from "react"
import parse from "html-react-parser"
import { Image } from "../../Image"
import { Link, navigate } from "gatsby"
import { BlogModal } from "../../Blog/BlogModal"
import { RecentPostsItem } from "./RecentPostsItem"
import Slider from "react-slick"
import { NextArrow, PrevArrow } from "../TestimonialsSlider/Arrows"

export function RecentPosts({ posts, blogTitle, blogText }) {
  const [modalContent, setModalContent] = useState(null)

  const recentPosts = posts.slice(0, 4)

  useEffect(() => {
    if (typeof window !== "undefined") {
      const hash = window.location.hash
      if (hash)
        setModalContent(recentPosts.find(({ slug }) => slug === hash.slice(1)))
    }

    // if (typeof document !== "undefined") document.documentElement.scrollTop = 0
  }, [recentPosts])

  const Modal = () => {
    if (modalContent) {
      navigate(`#${modalContent.slug}`)
      return (
        <BlogModal
          {...modalContent}
          closeHandler={post => {
            changeUrlCLose()
            setModalContent(post)
          }}
        />
      )
    }
    return null
  }

  const changeUrlCLose = () => {
    if (typeof window !== "undefined") navigate(window.location.pathname)
  }

  const settings = {
    dots: false,
    arrows: true,
    fade: false,
    speed: 400,
    autoplay: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: true,
    swipe: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          //centerMode: true,
          centerPadding: "17px",
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: "6%",
        },
      },
    ],
  }

  return (
    <div className="recent-posts">
      <div className="container">
        <div className="recent-posts__wrapper">
          <div className="recent-posts__top">
            <div>
              {blogTitle && <h2>{parse(blogTitle)}</h2>}
              {blogText && <p>{parse(blogText)}</p>}
            </div>
            <div>
              <Link to="/blog" className="recent-posts__link">
                Read all
              </Link>
            </div>
          </div>

          <Slider {...settings} className="recent-posts__slider">
            {recentPosts?.map((item, index) => (
              <RecentPostsItem
                {...item}
                onClickHandler={post => setModalContent(post)}
              />
            ))}
          </Slider>
        </div>
      </div>
      <Modal />
    </div>
  )
}
