import React from "react"
import parse from "html-react-parser"
import { Image } from "../../Image"
import { Link } from "gatsby"

export function LookingFor({
  lookingForTitle,
  lookingForDescription,
  lookingForList,
  lookingForImage,
  lookingForButtonText,
  lookingForButtonUrl,
}) {
  return (
    <div className="co-found-looking-for">
      <div className="container">
        <div className="co-found-looking-for__content">
          <div className="content-wrapper">
            <h2>{parse(lookingForTitle)}</h2>
            <p>{parse(lookingForDescription)}</p>
            {lookingForList.length && (
              <ul>
                {lookingForList.map(({ text }, index) => (
                  <li key={index}>{parse(text)}</li>
                ))}
              </ul>
            )}
            <div className="btn-wrapper">
              <Link
                to={lookingForButtonUrl.replace("https://atomic.vc", "")}
                className="btn btn-large btn-white"
              >
                {lookingForButtonText}
              </Link>
            </div>
          </div>
        </div>
        <div className="co-found-looking-for__image">
          <Image className="img" image={lookingForImage} />
        </div>
      </div>
    </div>
  )
}
