import React, { useEffect, useState } from "react"
import { graphql, navigate } from "gatsby"

import { Layout } from "../components/Layout"
import { FutureFoundersInfo } from "../components/FutureFounders/FutureFoundersInfo"
import { Faqs } from "../components/FutureFounders/Faqs/Faqs"
import { CompanyModal } from "../components/Companies/CompanyModal"
import { TestimonialsSlider } from "../components/FutureFounders/TestimonialsSlider/TestimonialsSlider"
import { Benefits } from "../components/FutureFounders/Benefits/Benefits"
import { FindCompany } from "../components/FutureFounders/FindCompany/FindCompany"
import { AboutText } from "../components/FutureFounders/About/AboutText"
import { LookingFor } from "../components/FutureFounders/LookingFor/LookingFor"
import { RecentPosts } from "../components/FutureFounders/RecentPosts/RecentPosts"
import { ModalUser } from "../components/Team/ModalUser"
import ApplicationModal from "../components/FutureFounders/ApplicationModal"

export default function CoFound({ data }) {
  const [activeCompany, setActiveCompany] = useState(false)
  const [memberModal, setMemberModal] = useState(false)
  const [modalForm, setModalForm] = useState(false)
  const [modalFormHash, setModalFormHash] = useState("#application")

  const {
    site: {
      siteMetadata: { title },
    },
    globalMetaData,
    barData,
    headerData,
    footerData,
    blogs: { blogSection },
    futureFounders: {
      metaData,
      topHeader,
      topButtonToggle,
      topButton,
      topButtonUrl,
      topDeadlineToggle,
      topDeadline,
      testimonials,
      benefits,
      aboutText: { aboutText },
      findCompanyTitle,
      findTheCompanyDescription: { findTheCompanyDescription },
      findTheCompanyCards,
      lookingForTitle,
      lookingForDescription: { lookingForDescription },
      lookingForList,
      lookingForButtonText,
      lookingForButtonUrl,
      lookingForImage,
      blogTitle,
      blogText: { blogText },
      faq,
      ctaTitle,
      ctaButtonToggle,
      ctaButtonText,
      ctaButtonUrl,
      ctaDeadlineToggle,
      ctaDeadline,
      tickerDuration,
      tickerData,
    },
  } = data

  useEffect(() => {
    if (typeof window !== "undefined") {
      const hash = window.location.hash
      if (hash) {
        const dataCompanies = testimonials.find(
          ({ refCompany }) => refCompany.slug === hash.slice(1)
        )
        if (dataCompanies) setActiveCompany(dataCompanies.refCompany)

        const dataMembers = testimonials.find(
          ({ referenceTeamMember }) =>
            referenceTeamMember.slug === hash.slice(1)
        )
        if (dataMembers) setMemberModal(dataMembers.referenceTeamMember)

        if (hash === "#success") {
          setModalFormHash("#success")
        }
        if (hash === "#application" || hash === "#success") {
          setModalForm(true)
        }
      }
    }

    if (typeof document !== "undefined") document.documentElement.scrollTop = 0
  }, [testimonials])

  const Modals = () => {
    if (activeCompany) {
      navigate(`#${activeCompany.slug}`)
      return (
        <CompanyModal
          {...activeCompany}
          onClose={() => {
            setActiveCompany(false)
            changeUrlCLose()
          }}
        />
      )
    }
    if (memberModal) {
      navigate(`#${memberModal.slug}`)
      return (
        <ModalUser
          {...memberModal}
          onClose={() => {
            changeUrlCLose()
            setMemberModal(null)
          }}
        />
      )
    }
    if (modalForm) navigate(modalFormHash)
    return null
  }

  const changeUrlCLose = () => {
    if (typeof window !== "undefined") navigate(window.location.pathname)
  }

  return (
    <Layout
      barData={barData}
      headerData={headerData}
      footerData={footerData}
      ctaType="cta-inner"
      ctaTitle={ctaTitle}
      ctaLink={{
        show: ctaButtonToggle, // default true
        url: ctaButtonUrl,
        title: ctaButtonText,
      }}
      ctaDeadline={ctaDeadlineToggle ? ctaDeadline : ""}
      tickerDuration={tickerDuration}
      tickerData={tickerData}
      pageTitle="Co-Found"
      title={title}
      globalMetaData={globalMetaData}
      metaData={metaData}
      setModalForm={() => setModalForm(true)}
      isFutureFounderBtn={false}
      className="co-found"
    >
      <div className="co-found__sections">
        <FutureFoundersInfo
          topHeader={topHeader}
          topButtonToggle={topButtonToggle}
          topButton={topButton}
          topButtonUrl={topButtonUrl}
          topDeadline={topDeadlineToggle ? topDeadline : ""}
          setModalForm={() => setModalForm(true)}
        />
        <TestimonialsSlider
          slidersCount={testimonials.length <= 4 ? testimonials.length : 4}
          slides={
            testimonials.length < 4
              ? [...testimonials, ...testimonials]
              : testimonials
          }
          setActiveCompany={setActiveCompany}
          setMemberModal={setMemberModal}
        />
        {benefits.length && <Benefits benefits={benefits} />}
        {aboutText && <AboutText aboutText={aboutText} />}
        <FindCompany
          findCompanyTitle={findCompanyTitle}
          findTheCompanyDescription={findTheCompanyDescription}
          findTheCompanyCards={findTheCompanyCards}
        />
        <LookingFor
          lookingForTitle={lookingForTitle}
          lookingForDescription={lookingForDescription}
          lookingForList={lookingForList}
          lookingForImage={lookingForImage}
          lookingForButtonText={lookingForButtonText}
          lookingForButtonUrl={lookingForButtonUrl}
        />
        <RecentPosts
          posts={blogSection}
          blogTitle={blogTitle}
          blogText={blogText}
        />
        <Faqs faq={faq} />
      </div>
      <Modals />
      <ApplicationModal
        onClose={() => {
          changeUrlCLose()
          setModalForm(false)
          setModalFormHash("#application")
        }}
        onFormSubmit={() => {
          changeUrlCLose()
          setModalFormHash("#success")
          navigate(`#success`)
        }}
        isOpen={modalForm}
      />
    </Layout>
  )
}

export const pageQuery = graphql`
  query FutureFounders2Query {
    site {
      siteMetadata {
        title
      }
    }
    globalMetaData: contentfulGlobalMetaData {
      desc {
        desc
      }
      keywords {
        keywords
      }
      image {
        file {
          src: url
        }
      }
    }
    barData: contentfulSectionAnnouncementBar {
      visible
      text
      link
    }
    headerData: contentfulSectionHeader {
      logo {
        file {
          src: url
        }
        alt: title
      }
      logoMobile {
        file {
          src: url
        }
        alt: title
      }
    }
    footerData: contentfulSectionFooter {
      email
      secondEmail
      social {
        icon {
          file {
            src: url
          }
          alt: title
        }
        link
      }
      newsletterTitle
      logo {
        file {
          src: url
        }
        alt: title
      }
      copyright
    }
    blogs: contentfulPageNews {
      blogSection {
        slug
        image {
          fluid(maxWidth: 760) {
            src
          }
          file {
            src: url
          }
          alt: title
        }
        title
        date
        shortContent {
          text: shortContent
        }
        fullContent {
          text: fullContent
        }
      }
    }
    futureFounders: contentfulPageFutureFounders2022 {
      metaData {
        title
        desc {
          desc
        }
        keywords {
          keywords
        }
        image {
          file {
            src: url
          }
        }
      }
      topHeader {
        title
        desc {
          text: desc
        }
      }
      topButtonToggle
      topButton
      topButtonUrl
      topDeadlineToggle
      topDeadline
      testimonials {
        text {
          text
        }
        role
        refCompany {
          slug
          name
          logoBlack {
            file {
              src: url
            }
            alt: title
          }
          title
          desc {
            text: desc
          }
          investors {
            name
          }
          links {
            link
          }
          images {
            fluid(maxWidth: 320) {
              ...GatsbyContentfulFluid
            }
            alt: title
          }
        }
        referenceTeamMember {
          slug
          smallPhoto {
            fluid(maxWidth: 317) {
              ...GatsbyContentfulFluid
            }
            alt: title
          }
          position
          name
          largePhoto {
            fluid(maxWidth: 760) {
              ...GatsbyContentfulFluid
            }
            alt: title
          }
          social {
            icon {
              file {
                src: url
              }
              alt: title
            }
            link
          }
          desc {
            text: desc
          }
          faq {
            title
            text {
              text
            }
          }
        }
      }
      benefits {
        title
        icon {
          fluid(maxWidth: 64) {
            ...GatsbyContentfulFluid
          }
          alt: title
        }
      }
      aboutText {
        aboutText
      }
      findCompanyTitle
      findTheCompanyDescription {
        findTheCompanyDescription
      }
      findTheCompanyCards {
        image {
          fluid(maxWidth: 460) {
            ...GatsbyContentfulFluid
          }
          alt: title
        }
        title
        description
      }
      lookingForTitle
      lookingForDescription {
        lookingForDescription
      }
      lookingForList {
        text
      }
      lookingForImage {
        fluid(maxWidth: 900) {
          ...GatsbyContentfulFluid
        }
        alt: title
      }
      lookingForButtonText
      lookingForButtonUrl
      blogTitle
      blogText {
        blogText
      }
      faq {
        title
        content {
          content
        }
      }
      ctaTitle
      ctaButtonToggle
      ctaButtonText
      ctaButtonUrl
      ctaDeadlineToggle
      ctaDeadline
      tickerDuration {
        duration
      }
      tickerData: ticker {
        text {
          text
        }
      }
    }
  }
`
