import * as React from "react"
import * as yup from "yup"
import { useFormik } from "formik"
import Recaptcha from "react-google-recaptcha"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

import NetlifyForm from "../NetlifyForm"
import { ModalWrapper } from "../ModalWrapper"

const RECAPTCHA_KEY = "6Lcf4IQaAAAAAEvHy2QajeAmGNSWPBFaW0jXRGw5"

const validationSchema = yup.object({
  name: yup.string("Enter your name").required("Name is required"),
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  phone: yup
    .string("Enter your phone number")
    .matches(
      /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
      "Enter a valid phone number"
    )
    .required("Phone number is required"),
  fileUrl: yup.string("Enter a valid URL").required("File Upload is required"),
  // linkedinUrl: yup
  //   .string("Enter your Linkedin URL")
  //   .url("Enter a valid URL")
  //   .required("Linkedin URL is required"),
  // twitterUrl: yup.string("Enter your twitter URL").url("Enter a valid URL"),
  // githubUrl: yup.string("Enter your github URL").url("Enter a valid URL"),
  // portfolioUrl: yup.string("Enter your portfolio URL").url("Enter a valid URL"),
  // otherLink: yup.string("Enter a valid URL").url("Enter a valid URL"),
  // onlinePresence: yup
  //   .string("Enter a valid URL")
  //   .url("Enter a valid URL")
  //   .required("Link is required"),
  onlinePresence: yup.string("Enter a valid URL").required("Link is required"),
  USEmploymentAuthorization: yup
    .string("Enter your US Employment Authorization")
    .required("US Employment Authorization is required"),
})

export default function ApplicationModal({ isOpen, onClose, onFormSubmit }) {
  const [isSent, setIsSent] = React.useState(false)
  const [recaptchaState, setRecaptchaState] = React.useState(false)
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      // linkedinUrl: "",
      // twitterUrl: "",
      // githubUrl: "",
      // portfolioUrl: "",
      // otherLink: "",
      onlinePresence: "",
      file: "",
      fileUrl: "",
      referrals: "",
      anythingElse: "",
      USEmploymentAuthorization: "",
      // gender: "",
      // race: "",
      // veteranStatus: "",
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      console.log("values", values) //eslint-disable-line

      const leverHeaders = new Headers()
      leverHeaders.append("Content-Type", "application/json")
      leverHeaders.append(
        "Authorization",
        "Basic cW1pUjJucnp4Z05ENUtKdTRsZTZ6NnR6WlIwb3lBcE9xSmg5TmZlVENOTFJrV0hWOg=="
      )

      const raw = JSON.stringify({
        customQuestions: [
          {
            id: "265e7d7d-ff80-43f5-ab87-a73740c894cf",
            fields: [
              {
                value: values.referrals,
              },
            ],
          },
          {
            id: "ea0848a5-f349-413d-849e-9883eb576e6d",
            fields: [
              {
                value: values.anythingElse,
              },
            ],
          },
          {
            id: "fb8ee292-8a58-4b25-b9c2-7758e26a2f69",
            fields: [
              {
                value: values.USEmploymentAuthorization,
              },
            ],
          },
          {
            id: "6caef7a8-c7fe-476f-b2ed-3dd621d73860",
            fields: [
              {
                value: values.fileUrl,
              },
            ],
          },
        ],
        personalInformation: [
          { name: "fullName", value: values.name },
          { name: "email", value: values.email },
          { name: "phone", value: values.phone },
          {
            name: "resume",
            value: values.fileUrl,
          },
          // {
          //   name: "additionalInformation",
          //   value: `Gender: ${values.gender}; Race: ${values.race}; Veteran Status: ${values.veteranStatus}`,
          // },
        ],
        urls: [{ name: "Other", value: values.onlinePresence }],
      })

      const requestOptions = {
        method: "POST",
        headers: leverHeaders,
        body: raw,
        redirect: "follow",
      }

      fetch(
        "https://api.icors.workers.dev/?https://api.lever.co/v1/postings/4298e1db-3bbc-42c6-a863-fb6e6dc46aa7/apply",
        requestOptions
      )
        .then(response => response.json())
        .then(result => console.log(result))
        .catch(error => console.log("error", error))

      trackCustomEvent({
        category: "Future Founders Miami",
        action: "Application Submission",
      })

      return true
    },
  })

  React.useEffect(() => {
    if (typeof window !== "undefined") {
      const hash = window.location.hash
      if (hash && !isSent) {
        if (hash === "#success") setIsSent(true)
      }
    }

    if (typeof document !== "undefined") document.documentElement.scrollTop = 0
  }, [])

  const postSubmit = () => {
    onFormSubmit()
    setIsSent(true)
  }

  /*
  const themeSelect = theme => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary: "#F2571F",
      primary75: "rgb(242, 87, 31, .75)",
      primary50: "rgb(242, 87, 31, .5)",
      primary25: "rgb(242, 87, 31, .25)",
    },
  })
*/

  const isValid = !!formik.values.name && !formik.errors.name && formik.isValid
  return (
    <>
      <NetlifyForm
        formName="Future Founder"
        formValues={formik.values}
        preSubmit={formik.handleSubmit}
        postSubmit={postSubmit}
        isValid={isValid}
      >
        <input
          type="text"
          name="name"
          defaultValue={formik.values.name}
          hidden
        />
        <input
          type="text"
          name="email"
          defaultValue={formik.values.email}
          hidden
        />
        <input
          type="text"
          name="phone"
          defaultValue={formik.values.phone}
          hidden
        />
        {/*
        <input
          type="text"
          name="linkedinUrl"
          defaultValue={formik.values.linkedinUrl}
          hidden
        />
        <input
          type="text"
          name="twitterUrl"
          defaultValue={formik.values.twitterUrl}
          hidden
        />
        <input
          type="text"
          name="githubUrl"
          defaultValue={formik.values.githubUrl}
          hidden
        />
        <input
          type="text"
          name="portfolioUrl"
          defaultValue={formik.values.portfolioUrl}
          hidden
        />
        <input
          type="text"
          name="otherLink"
          defaultValue={formik.values.otherLink}
          hidden
        />
*/}
        <input
          type="text"
          name="onlinePresence"
          defaultValue={formik.values.onlinePresence}
          hidden
        />
        <input
          type="file"
          name="file"
          defaultValue={formik.values.file}
          hidden
        />
        <input
          type="text"
          name="referrals"
          defaultValue={formik.values.referrals}
          hidden
        />
        {/*
        <textarea
          name="areasOfInterest"
          defaultValue={formik.values.areasOfInterest}
          hidden
        />
*/}
        <textarea
          name="anythingElse"
          defaultValue={formik.values.anythingElse}
          hidden
        />
        <input
          type="text"
          name="USEmploymentAuthorization"
          defaultValue={formik.values.USEmploymentAuthorization}
          hidden
        />
        <input
          type="text"
          name="gender"
          defaultValue={formik.values.gender}
          hidden
        />
        <input
          type="text"
          name="race"
          defaultValue={formik.values.race}
          hidden
        />
        <input
          type="text"
          name="veteranStatus"
          defaultValue={formik.values.veteranStatus}
          hidden
        />
      </NetlifyForm>
      {isOpen && (
        <ModalWrapper
          onClose={() => {
            onClose()
            setIsSent(false)
          }}
          modalType="custom-form"
        >
          <div className="modal-form-wrapper">
            <h2>Future Founders Miami</h2>
            {!isSent ? (
              <>
                <NetlifyForm
                  formName="Future Founder"
                  formValues={formik.values}
                  postSubmit={postSubmit}
                  preSubmit={formik.handleSubmit}
                  isValid={isValid}
                >
                  <div
                    className={[
                      "field-wrapper",
                      formik.touched.name &&
                        Boolean(formik.errors.name) &&
                        "error",
                    ]
                      .filter(Boolean)
                      .join(" ")}
                  >
                    <label htmlFor="name">Your name *</label>
                    <input
                      id="name"
                      type="text"
                      name="name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                    />
                    {formik.touched.name && Boolean(formik.errors.name) && (
                      <span className="helper-text">{formik.errors.name}</span>
                    )}
                  </div>
                  <div
                    className={[
                      "field-wrapper",
                      formik.touched.email &&
                        Boolean(formik.errors.email) &&
                        "error",
                    ]
                      .filter(Boolean)
                      .join(" ")}
                  >
                    <label htmlFor="email">Email address *</label>
                    <input
                      id="email"
                      type="email"
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                    />
                    {formik.touched.email && Boolean(formik.errors.email) && (
                      <span className="helper-text">{formik.errors.email}</span>
                    )}
                  </div>
                  <div
                    className={[
                      "field-wrapper",
                      formik.touched.phone &&
                        Boolean(formik.errors.phone) &&
                        "error",
                    ]
                      .filter(Boolean)
                      .join(" ")}
                  >
                    <label htmlFor="phone">Phone number *</label>
                    <input
                      id="phone"
                      type="tel"
                      name="phone"
                      value={formik.values.phone}
                      onChange={formik.handleChange}
                    />
                    {formik.touched.phone && Boolean(formik.errors.phone) && (
                      <span className="helper-text">{formik.errors.phone}</span>
                    )}
                  </div>
                  <h3>Online Presence</h3>
                  <div className="comment">
                    Show us who you are online – this could be your Twitter,
                    LinkedIn, Github, Behance portfolio, a personal website,
                    etc.
                  </div>
                  {/*
                  <div
                    className={[
                      "field-wrapper",
                      formik.touched.linkedinUrl &&
                        Boolean(formik.errors.linkedinUrl) &&
                        "error",
                    ]
                      .filter(Boolean)
                      .join(" ")}
                  >
                    <label htmlFor="linkedinUrl">Linkedin URL *</label>
                    <input
                      id="linkedinUrl"
                      type="text"
                      name="linkedinUrl"
                      value={formik.values.linkedinUrl}
                      onChange={formik.handleChange}
                    />
                    {formik.touched.linkedinUrl &&
                      Boolean(formik.errors.linkedinUrl) && (
                        <span className="helper-text">
                          {formik.errors.linkedinUrl}
                        </span>
                      )}
                  </div>
                  <div
                    className={[
                      "field-wrapper",
                      formik.touched.twitterUrl &&
                        Boolean(formik.errors.twitterUrl) &&
                        "error",
                    ]
                      .filter(Boolean)
                      .join(" ")}
                  >
                    <label htmlFor="twitterUrl">Twitter URL</label>
                    <input
                      id="twitterUrl"
                      type="text"
                      name="twitterUrl"
                      value={formik.values.twitterUrl}
                      onChange={formik.handleChange}
                    />
                    {formik.touched.twitterUrl &&
                      Boolean(formik.errors.twitterUrl) && (
                        <span className="helper-text">
                          {formik.errors.twitterUrl}
                        </span>
                      )}
                  </div>
                  <div
                    className={[
                      "field-wrapper",
                      formik.touched.githubUrl &&
                        Boolean(formik.errors.githubUrl) &&
                        "error",
                    ]
                      .filter(Boolean)
                      .join(" ")}
                  >
                    <label htmlFor="githubUrl">Github URL</label>
                    <input
                      id="githubUrl"
                      type="text"
                      name="githubUrl"
                      value={formik.values.githubUrl}
                      onChange={formik.handleChange}
                    />
                    {formik.touched.githubUrl &&
                      Boolean(formik.errors.githubUrl) && (
                        <span className="helper-text">
                          {formik.errors.githubUrl}
                        </span>
                      )}
                  </div>
                  <div
                    className={[
                      "field-wrapper",
                      formik.touched.portfolioUrl &&
                        Boolean(formik.errors.portfolioUrl) &&
                        "error",
                    ]
                      .filter(Boolean)
                      .join(" ")}
                  >
                    <label htmlFor="portfolioUrl">Portfolio URL</label>
                    <input
                      id="portfolioUrl"
                      type="text"
                      name="portfolioUrl"
                      value={formik.values.portfolioUrl}
                      onChange={formik.handleChange}
                    />
                    {formik.touched.portfolioUrl &&
                      Boolean(formik.errors.portfolioUrl) && (
                        <span className="helper-text">
                          {formik.errors.portfolioUrl}
                        </span>
                      )}
                  </div>
                  <div
                    className={[
                      "field-wrapper",
                      formik.touched.otherLink &&
                        Boolean(formik.errors.otherLink) &&
                        "error",
                    ]
                      .filter(Boolean)
                      .join(" ")}
                  >
                    <label htmlFor="otherLink">Other link</label>
                    <input
                      id="otherLink"
                      type="text"
                      name="otherLink"
                      value={formik.values.otherLink}
                      onChange={formik.handleChange}
                    />
                    {formik.touched.otherLink &&
                      Boolean(formik.errors.otherLink) && (
                        <span className="helper-text">
                          {formik.errors.otherLink}
                        </span>
                      )}
                  </div>
*/}
                  <div
                    className={[
                      "field-wrapper",
                      formik.touched.onlinePresence &&
                        Boolean(formik.errors.onlinePresence) &&
                        "error",
                    ]
                      .filter(Boolean)
                      .join(" ")}
                  >
                    <label htmlFor="otherLink">Link*</label>
                    <input
                      id="onlinePresence"
                      type="text"
                      name="onlinePresence"
                      value={formik.values.onlinePresence}
                      onChange={formik.handleChange}
                    />
                    {formik.touched.onlinePresence &&
                      Boolean(formik.errors.onlinePresence) && (
                        <span className="helper-text">
                          {formik.errors.onlinePresence}
                        </span>
                      )}
                  </div>
                  <h3>Additional Options</h3>
                  <div className="comment">
                    Feel free to submit anything else you feel would be helpful
                    or would like us to consider – resume, code, website, slide
                    deck, video, etc.*
                  </div>
                  <div
                    className={[
                      "field-wrapper file-wrapper",
                      formik.touched.fileUrl &&
                        Boolean(formik.errors.fileUrl) &&
                        "error",
                    ]
                      .filter(Boolean)
                      .join(" ")}
                  >
                    {!formik.values.file ? (
                      <>
                        <label className="label-file" htmlFor="file">
                          Upload File
                        </label>
                        <input
                          id="file"
                          type="file"
                          name="file"
                          onChange={e => {
                            formik.setFieldValue("file", e.target.files[0])

                            const leverHeaders = new Headers()
                            leverHeaders.append(
                              "Authorization",
                              "Basic cW1pUjJucnp4Z05ENUtKdTRsZTZ6NnR6WlIwb3lBcE9xSmg5TmZlVENOTFJrV0hWOg=="
                            )

                            const formdata = new FormData()
                            formdata.append(
                              "file",
                              e.target.files[0],
                              e.target.files[0].name
                            )

                            const requestOptions = {
                              method: "POST",
                              headers: leverHeaders,
                              body: formdata,
                              redirect: "follow",
                            }

                            fetch(
                              "https://api.icors.workers.dev/?https://api.lever.co/v1/uploads",
                              requestOptions
                            )
                              .then(response => response.json())
                              .then(result => {
                                console.log("uploaded file", result.data)
                                formik.setFieldValue(
                                  "fileUrl",
                                  result?.data?.uri
                                )
                              })
                              .catch(error => console.log("error", error))
                          }}
                        />
                        {formik.touched.fileUrl &&
                          Boolean(formik.errors.fileUrl) && (
                            <span className="helper-text">
                              {formik.errors.fileUrl}
                            </span>
                          )}
                      </>
                    ) : (
                      <>
                        <div className="uploaded-button">File Uploaded</div>
                        <button
                          className="uploaded-button-close"
                          onClick={() => {
                            formik.setFieldValue("file", "")
                            formik.setFieldValue("fileUrl", "")
                          }}
                        />
                      </>
                    )}
                  </div>
                  <h3>Referrals</h3>
                  <div className="comment">
                    Know someone who would be a good fit for the Future Founders
                    program? Please share with us their Linkedin or email
                    address.
                  </div>
                  <div className="field-wrapper">
                    <label htmlFor="referrals">Linkedin or email</label>
                    <input
                      id="referrals"
                      type="text"
                      name="referrals"
                      value={formik.values.referrals}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <h3>Anything else?</h3>
                  <div className="comment">
                    You can add a note, cover letter, areas of interest, unique
                    background in a specific vertical, or anything else you
                    might want to share.
                  </div>
                  <textarea
                    id="anythingElse"
                    name="anythingElse"
                    value={formik.values.anythingElse}
                    onChange={formik.handleChange}
                  />
                  <h3>US Employment Authorization</h3>
                  <div className="comment">
                    Do you now, or will you in the future, require sponsorship
                    for employment visa status to work legally for our Company
                    in the US? *
                  </div>
                  <div
                    className={[
                      "field-wrapper",
                      "radio-wrapper",
                      formik.touched.USEmploymentAuthorization &&
                        Boolean(formik.errors.USEmploymentAuthorization) &&
                        "error",
                    ]
                      .filter(Boolean)
                      .join(" ")}
                  >
                    <input
                      id="USEmploymentAuthorizationYes"
                      type="radio"
                      name="USEmploymentAuthorization"
                      value="Yes"
                      onChange={formik.handleChange}
                      checked={
                        formik.values.USEmploymentAuthorization === "Yes"
                      }
                    />
                    <label
                      className="label-container"
                      htmlFor="USEmploymentAuthorizationYes"
                    >
                      Yes
                    </label>
                    <input
                      id="USEmploymentAuthorizationNo"
                      type="radio"
                      name="USEmploymentAuthorization"
                      value="No"
                      onChange={formik.handleChange}
                      checked={formik.values.USEmploymentAuthorization === "No"}
                    />
                    <label
                      className="label-container"
                      htmlFor="USEmploymentAuthorizationNo"
                    >
                      No
                    </label>
                    {formik.touched.USEmploymentAuthorization &&
                      Boolean(formik.errors.USEmploymentAuthorization) && (
                        <span className="helper-text">
                          {formik.errors.USEmploymentAuthorization}
                        </span>
                      )}
                  </div>
                  {/*
                  <h3>Equal Employment Opportunity Information</h3>
                  <div className="comment">
                    Completion is voluntary and will not subject you to adverse
                    treatment.
                    <br />
                    <br />
                    Our company values diversity. To ensure that we comply with
                    reporting requirements and to learn more about how we can
                    increase diversity in our candidate pool, we invite you to
                    voluntarily provide demographic information in a
                    confidential survey at the end of this application.
                    Providing this information is optional. It will not be
                    accessible or used in the hiring process, and has no effect
                    on your opportunity for employment.
                  </div>
                  <div className="field-wrapper">
                    <label htmlFor="referrals">Gender</label>
                    <Select
                      className="custom-select"
                      classNamePrefix="custom-select"
                      isSearchable={false}
                      options={[
                        { value: "Male", label: "Male" },
                        { value: "Female", label: "Female" },
                        {
                          value: "Decline to self-identify",
                          label: "Decline to self-identify",
                        },
                      ]}
                      theme={themeSelect}
                      onChange={option =>
                        formik.setFieldValue("gender", option.value)
                      }
                    />
                  </div>
                  <div className="field-wrapper">
                    <label htmlFor="referrals">Race</label>
                    <Select
                      className="custom-select"
                      classNamePrefix="custom-select"
                      isSearchable={false}
                      options={[
                        {
                          value: "Hispanic or Latino",
                          label: "Hispanic or Latino",
                        },
                        {
                          value: "White (Not Hispanic or Latino)",
                          label: "White (Not Hispanic or Latino)",
                        },
                        {
                          value:
                            "Black or African American (Not Hispanic or Latino)",
                          label:
                            "Black or African American (Not Hispanic or Latino)",
                        },
                        {
                          value:
                            "Native Hawaiian or Other Pacific Islander (Not Hispanic or Latino)",
                          label:
                            "Native Hawaiian or Other Pacific Islander (Not Hispanic or Latino)",
                        },
                        {
                          value: "Asian (Not Hispanic or Latino)",
                          label: "Asian (Not Hispanic or Latino)",
                        },
                        {
                          value:
                            "American Indian or Alaska Native (Not Hispanic or Latino)",
                          label:
                            "American Indian or Alaska Native (Not Hispanic or Latino)",
                        },
                        {
                          value: "Two or More Races (Not Hispanic or Latino)",
                          label: "Two or More Races (Not Hispanic or Latino)",
                        },
                        {
                          value: "Decline to self-identify",
                          label: "Decline to self-identify",
                        },
                      ]}
                      theme={themeSelect}
                      onChange={option =>
                        formik.setFieldValue("race", option.value)
                      }
                    />
                  </div>
                  <div className="field-wrapper">
                    <label htmlFor="referrals">Veteran Status</label>
                    <Select
                      className="custom-select"
                      classNamePrefix="custom-select"
                      isSearchable={false}
                      options={[
                        { value: "I am a veteran", label: "I am a veteran" },
                        {
                          value: "I am not a veteran",
                          label: "I am not a veteran",
                        },
                        {
                          value: "Decline to self-identify",
                          label: "Decline to self-identify",
                        },
                      ]}
                      theme={themeSelect}
                      onChange={option =>
                        formik.setFieldValue("veteranStatus", option.value)
                      }
                    />
                  </div>
*/}
                  <Recaptcha
                    style={{ marginTop: "32px" }}
                    sitekey={RECAPTCHA_KEY}
                    onChange={value => setRecaptchaState(!!value)}
                  />
                  <div className="btn-wrapper">
                    {!isValid && (
                      <span className="helper-text">
                        Please fill in the required fields correctly
                      </span>
                    )}
                    <button
                      type="submit"
                      className="btn btn-black btn-block"
                      // disabled={recaptchaState}
                      disabled={!recaptchaState}
                    >
                      Submit Application
                    </button>
                  </div>
                </NetlifyForm>
              </>
            ) : (
              <>
                <h3 className="gutter-top">Success!</h3>
                <span className="success-comment">
                  Thank you for your application.
                </span>
              </>
            )}
          </div>
        </ModalWrapper>
      )}
    </>
  )
}
