import React from "react"
import parse from "html-react-parser"

export const AboutText = ({ aboutText }) => (
  <div className="about-text">
    <div className="container">
      <div className="about-text__wrapper">
        <p>{parse(aboutText)}</p>
      </div>
    </div>
  </div>
)
